<template>
  <div class="main">
    <div class="detailAction">
      <van-button
        size="small"
        type="primary"
        v-if="detail.tempstatus == 2 && actionsLength > 0"
        @click="showAssessment = true"
        >评估审批</van-button
      >
      <van-button
        size="small"
        :to="
          '/detectionTask/' +
            detail.id +
            '/' +
            detail.tempptw_cetificate_type_id +
            '/' +
            detail.temparea
        "
        type="primary"
        v-if="detail.tempstatus == 4 && actionsLength > 0"
        >发布任务</van-button
      >
      <van-button
        size="small"
        type="primary"
        :to="
          '/sign/' +
            detail.id +
            '/' +
            detail.tempptw_cetificate_type_id +
            '/' +
            (detail.tempcompany_fire_watch || '0') +
            '/' +
            (detail.tempct_fire_watch || '0') +
            '/' +
            (detail.supervisor_id != '' ? detail.supervisor_id : 0) +
            '/' +
            (tempwork_level || 0) +
            '/' +
            detail.tempstatus
        "
        v-if="tempstatus == 7 && actionsLength > 0"
        >签发</van-button
      >
      <van-button
        size="small"
        :to="'/sitePatrol/' + detail.id + '/' + detail.gas_inspection_location"
        type="primary"
        v-if="
          detail.tempstatus == 8 &&
            (detail.tempptw_cetificate_type_id == 7 ||
              detail.tempptw_cetificate_type_id == 8) &&
            ptw_gas_checker.search(detail.tempgas_checker) != -1
        "
        >现场作业</van-button
      >
      <van-button
        size="small"
        @click="cancelSubmit(detail.id)"
        type="danger"
        v-if="
          detail.tempstatus == '8' && detail.templocal_checker == app_user_id
        "
        >中止</van-button
      >
      <van-button
        size="small"
        :to="
          '/delay/' +
            detail.id +
            '/' +
            detail.start_date +
            '/' +
            detail.end_date
        "
        type="warning"
        v-if="detail.tempstatus == 8 && actionsLength > 0"
        >延期申请</van-button
      >

      <van-button
        size="small"
        :to="
          '/delayTask/' + detail.id + '/' + detail.tempptw_cetificate_type_id
        "
        type="primary"
        v-if="detail.tempstatus == 12 && actionsLength > 0"
        >延期审批</van-button
      >
      <van-button
        size="small"
        :to="
          '/siteConfrim/' +
            detail.id +
            '/' +
            detail.tempptw_cetificate_type_id +
            '/' +
            detail.gas_inspection_location +
            '/' +
            detail.tempstatus
        "
        type="primary"
        v-if="detail.tempstatus == 18 && actionsLength > 0"
        >延期现场确认</van-button
      >
      <van-button
        size="small"
        :to="
          '/siteConfrim/' +
            detail.id +
            '/' +
            detail.tempptw_cetificate_type_id +
            '/' +
            detail.gas_inspection_location
        "
        type="primary"
        v-if="detail.tempstatus == 5 && actionsLength > 0"
        >现场确认</van-button
      >
      <van-button
        size="small"
        :to="
          '/sign/' +
            detail.id +
            '/' +
            detail.tempptw_cetificate_type_id +
            '/' +
            (detail.tempcompany_fire_watch || '0') +
            '/' +
            (detail.tempct_fire_watch || '0') +
            '/' +
            (detail.supervisor_id != '' ? detail.supervisor_id : 0) +
            '/' +
            (tempwork_level || 0) +
            '/' +
            detail.tempstatus
        "
        type="primary"
        v-if="detail.tempstatus == 19 && actionsLength > 0"
        >延期签发</van-button
      >

      <van-button
        size="small"
        @click="taskSubmit(detail.id, '是否确认开始作业?', 0)"
        type="primary"
        v-if="detail.tempstatus == 16 && actionsLength > 0"
        >开始作业</van-button
      >
      <van-button
        size="small"
        @click="taskSubmit(detail.id, '是否提交完工申请？', 0)"
        type="success"
        v-if="detail.tempstatus == 8 && actionsLength > 0"
        >完工申请</van-button
      >
      <van-button
        size="small"
        type="primary"
        :to="'/acceptance/' + detail.id"
        v-if="detail.tempstatus == 14 && actionsLength > 0"
        >发布验收任务</van-button
      >
      <van-button
        size="small"
        type="primary"
        :to="'/acceptanceCheck/' + detail.id + '/' + detail.end_date"
        v-if="
          (detail.tempstatus == 9 || detail.tempstatus == 10) &&
            actionsLength > 0
        "
        >{{ detail.tempstatus == 9 ? "现场验收" : "整改验收" }}</van-button
      >
      <van-button
        size="small"
        type="primary"
        :to="
          '/closePtw/' +
            detail.id +
            '/' +
            detail.start_date +
            '/' +
            detail.end_date
        "
        v-if="detail.tempstatus == 17 && actionsLength > 0"
        >关票操作</van-button
      >
    </div>
    <van-cell-group
      :style="{
        height:
          detail.tempstatus != 8 && actionsLength > 0
            ? 'calc(100% - 45px)'
            : '100%',
        'overflow-y': 'auto'
      }"
    >
      <van-cell title="作业票编号" :value="detail.code" />
      <van-cell title="作业状态" :value="detail.status" />
      <van-cell title="申请单位/部门" :value="detail.department_name" />
      <van-cell title="申请人" :value="detail.applicant" />
      <van-cell title="作业许可证种类" :value="detail.ptw_cetificate_type_id" />
      <van-cell title="作业(检维修)种类" :value="detail.work_repair_type" />
      <van-cell
        title="作业级别"
        :value="detail.work_level"
        v-if="
          detail.tempptw_cetificate_type_id == 7 ||
            detail.tempptw_cetificate_type_id == 10 ||
            detail.tempptw_cetificate_type_id == 11
        "
      />
      <!-- 动火许可证 -->
      <van-cell
        title="动火方式"
        :value="detail.fire_type"
        v-if="detail.tempptw_cetificate_type_id == 7"
      />
      <!-- 高处作业 -->
      <van-cell
        title="作业高度(m)"
        :value="detail.height"
        v-if="detail.tempptw_cetificate_type_id == 10"
      />
      <!-- 吊装作业 -->
      <van-cell
        title="吊装人员及证书号"
        :label="detail.hoisting_person"
        v-if="detail.tempptw_cetificate_type_id == 11"
      />
      <van-cell
        title="吊装指挥及证书号"
        :label="detail.hoisting_certificate"
        v-if="detail.tempptw_cetificate_type_id == 11"
      />
      <van-cell
        title="起吊重物质量t"
        :value="detail.weight"
        v-if="detail.tempptw_cetificate_type_id == 11"
      />
      <!-- 临时用电 -->
      <van-cell
        title="用电功率(kw)"
        :value="detail.electrical_power"
        v-if="detail.tempptw_cetificate_type_id == 12"
      />
      <van-cell
        title="电源接入"
        :value="detail.power_access_point"
        v-if="detail.tempptw_cetificate_type_id == 12"
      />
      <van-cell
        title="工作电压(V)"
        :value="detail.electrical_pressure"
        v-if="detail.tempptw_cetificate_type_id == 12"
      />
      <van-cell title="预计开始时间" :value="detail.start_date" />
      <van-cell title="预计结束时间" :value="detail.end_date" />

      <van-cell title="实际开始时间" :value="detail.real_start_date" />
      <van-cell title="实际结束时间" :value="detail.real_end_date" />
      <van-cell title="作业内容描述" :label="detail.area_description" />

      <van-cell title="作业装置" :value="detail.ptw_unit_id" />
      <van-cell title="作业区域名称" :value="detail.area" />
      <van-cell title="作业区域类型" :value="detail.area_type" />
      <van-cell title="设备类型" :value="detail.device_type" />
      <van-cell title="设备/管道名称" :value="detail.device_no" />
      <!-- 受限空间作业 -->
      <van-cell
        title="受限空间内原有介质名称"
        :label="detail.medium"
        v-if="detail.tempptw_cetificate_type_id == 8"
      />
      <!-- 盲板抽堵作业 -->
      <van-cell
        title="介质"
        :label="detail.medium"
        v-if="detail.tempptw_cetificate_type_id == 9"
      />
      <van-cell
        title="温度(℃)"
        :label="detail.temperature"
        v-if="detail.tempptw_cetificate_type_id == 9"
      />
      <van-cell
        title="压力(Mpa)"
        :label="detail.pressure"
        v-if="detail.tempptw_cetificate_type_id == 9"
      />

      <van-cell
        title="作业单位"
        :label="
          (detail.ct_contractor_info_id || '') +
            ',' +
            (detail.organization_name || '') +
            ',' +
            (detail.temp_ct_worker_name || '')
        "
      />
      <van-cell
        title="作业人员"
        :label="
          (detail.work_id || '') +
            ',' +
            (detail.company_worker || '') +
            ',' +
            (detail.temp_ct_worker || '')
        "
      />
      <van-cell
        title="作业监护人"
        :label="
          (detail.supervisor_id || '') +
            ',' +
            (detail.temp_ct_fire_watch || '') +
            ',' +
            (detail.company_fire_watch || '')
        "
      />

      <van-cell title="作业工具类型" :value="detail.tool_type" />
      <van-cell title="作业工具名称" :value="detail.tool_name" />
      <!-- 盲板抽堵作业 -->
      <!-- JSA -->
      <van-cell title="JSA编号" :value="detail.ptw_jsa_id" />
      <van-card
        style="background-color:transparent;border-bottom:1px solid #cccccc"
        v-if="detail.ptw_jsa_id"
      >
        <template #desc>
          <div
            class="descBox"
            v-for="(items, index) in jsaDetail.PTW_JSA_ESTIMATE"
            :key="index"
          >
            <van-cell title="作业步骤" :value="items.name" />
            <!-- <van-cell title="风险源" :value="items.risk_source" />
                        <van-cell title="风险后果" :value="items.risk_result" /> -->
            <van-cell
              title="现有措施"
              :label="getStr(items.PTW_JSA_ESTIMATE_MEASURE, 1)"
            />
            <!-- <van-cell title="风险等级" :value="items.risk_estimate_level" /> -->
            <van-cell
              title="额外的安全措施"
              :label="getStr(items.PTW_JSA_ESTIMATE_MEASURE, 2)"
            />
            <!-- <van-cell title="采取措施后的风险等级" :value="items.risk_result_level" /> -->
          </div>
          <van-cell title="评估部门" :label="jsaDetail.department_ids" />
          <van-cell title="是否关联LOTO" :value="jsaDetail.related_loto" />
          <van-cell title="LOTO编号" :value="jsaDetail.loto_no" />
        </template>
      </van-card>
      <!-- 安全检查 -->
      <van-divider
        :style="{
          color: '#000000',
          borderColor: '#1989fa',
          padding: '0 5px',
          margin: '0'
        }"
        >作业票检查项</van-divider
      >
      <div
        class="checkBox"
        v-for="(item, key, index) in checkList"
        :key="index"
      >
        <div class="title">{{ key }}</div>
        <div class="content" v-for="(ele, index) in item" :key="index">
          <van-cell :value="index + 1 + '、' + ele.name" />
          <van-cell title="检查结果" :value="ele.answer" />
          <van-field v-if="ele.attachment1.length > 0" label="图片">
            <template #input>
              <van-icon
                name="chat-o"
                @click="showImg(ele.attachment1)"
                size="40"
                :badge="ele.attachment1.length"
              />
            </template>
          </van-field>
          <van-cell
            title="检查描述"
            :label="ele.commention"
            v-if="ele.commention"
          />
        </div>
      </div>

      <van-cell title="PPE" :label="detail.ppe_type" />
      <van-cell title="其他PPE内容" :label="detail.ppe_type_other" />
      <van-cell
        title="相关联许可证编号"
        :label="getOtherPtw(detail.LIST_PTW_CETIFICATE_RELATED)"
      />

      <van-cell
        v-if="
          (detail.tempptw_cetificate_type_id == 7 ||
            detail.tempptw_cetificate_type_id == 8) &&
            detail.PTW_GAS_INSPECTION &&
            detail.PTW_GAS_INSPECTION.length > 0
        "
        title="气体分析"
        :label="
          '气体分析与动火作业间隔不超过 ' +
            detail.gas_inspection_analysis_frequence +
            ' 小时；若作业中断超过 ' +
            detail.gas_inspection_exceed_hour +
            ' 小时，需重新气体分析。气体检测间隔时间为 ' +
            detail.gas_checker_frequence +
            ' 小时'
        "
      />
      <van-cell
        v-if="
          (detail.tempptw_cetificate_type_id == 7 ||
            detail.tempptw_cetificate_type_id == 8) &&
            detail.PTW_GAS_INSPECTION &&
            detail.PTW_GAS_INSPECTION.length > 0
        "
        title="气体分析设备编号"
        :value="detail.gas_inspection_device_code"
      />
      <van-divider
        v-if="
          (detail.tempptw_cetificate_type_id == 7 ||
            detail.tempptw_cetificate_type_id == 8) &&
            detail.PTW_GAS_INSPECTION &&
            detail.PTW_GAS_INSPECTION.length > 0
        "
        :style="{
          color: '#000000',
          borderColor: '#1989fa',
          padding: '0 5px',
          margin: '0'
        }"
        >气体检测</van-divider
      >
      <template
        v-if="
          (detail.tempptw_cetificate_type_id == 7 ||
            detail.tempptw_cetificate_type_id == 8) &&
            detail.PTW_GAS_INSPECTION &&
            detail.PTW_GAS_INSPECTION.length > 0
        "
      >
        <van-card
          v-for="(items, index) in detail.PTW_GAS_INSPECTION || []"
          :key="index"
          style="background-color:transparent;border-bottom:1px solid #cccccc"
        >
          <template #desc>
            <div class="descBox">
              <van-cell title="检测时间" :value="items.monitor_date" />
              <van-cell title="检测人" :value="items.create_by" />
              <van-cell
                title="分析点位置"
                :value="items.ptw_gas_inspection_address"
              />
              <van-cell title="可燃气体%LEL" :value="items.lel" />
              <van-cell title="有毒气体PPM" :value="items.ppm" />
              <van-cell title="o2" :value="items.o2" />
              <van-cell title="备注" :label="items.commention" />
            </div>
          </template>
        </van-card>
      </template>
    </van-cell-group>
    <!-- 签名 -->
    <handwritingHtml
      @writingCommit="writingCommit"
      ref="wirteDialog"
    ></handwritingHtml>
    <!-- 评估 -->
    <van-popup
      v-model:show="showAssessment"
      position="bottom"
      :style="{ width: '100%', height: '30%' }"
      :closeable="true"
      :safe-area-inset-bottom="true"
      :close-on-click-overlay="false"
    >
      <div
        class="centerBox"
        style="padding-top: 50px;height: calc(100% - 50px);"
      >
        <van-field
          label-width="50px"
          v-model="job_msg"
          rows="1"
          autosize
          label="原因"
          type="textarea"
          placeholder="请输入原因"
          style="border-top: 1px solid #eeeeee;"
        />
        <div class="loginoutBox">
          <van-button
            type="success"
            block
            size="small"
            @click="assessment('review')"
            >通过</van-button
          >
          <van-button type="danger" block size="small" @click="assessmentBack()"
            >退回</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable */
import handwritingHtml from '../../../components/handwriting.vue';
import {onMounted,ref,reactive, getCurrentInstance} from 'vue';
import {Dialog,Notify,ImagePreview} from 'vant';
import {useRoute} from 'vue-router';
import Http from '../../../api/http';
export default {
    components:{handwritingHtml},
    setup() {
        const writingType = ref('');
        const job_msg = ref('');

        const route = useRoute();
        const $this = getCurrentInstance();
        const app_user_id = ref(localStorage.getItem('app_user_id'))
        const ptw_gas_checker = ref(localStorage.getItem('ptw_gas_checker'))
        const detail = reactive({});
        const actionsLength = ref(0);
        const getOtherPtw = (list) =>{
            var l = [];
            if(list && list.length > 0){
                l = list.map(items=>items.code);
            }
            return l.join(',');
        }
        // 获取jsa
        const jsaDetail = reactive({
            PTW_JSA_ESTIMATE:[],
            related_loto:'',
            loto_no:'',
            department_ids:'',
            create_by:''
        });
        const getJsaDetail = (id) =>{
            Http.get({
                method:'query',
                queryId:217,
                id:id
            })
            .then(res =>{
                var maplist = res.map;
                jsaDetail.PTW_JSA_ESTIMATE = maplist.PTW_JSA_ESTIMATE;
                jsaDetail.related_loto = maplist.related_loto;
                jsaDetail.loto_no = maplist.loto_no;
                jsaDetail.department_ids = maplist.department_ids;
                jsaDetail.create_by = maplist.create_by;
            })
            .catch(()=>{});
        }
         const getStr = (list,types) =>{
            var rtL = []
            if(!list || list.length == 0) return '';
            list.forEach(elem=>{
                if(elem.types == types){
                rtL.push(elem.name)
                }
            });
            return rtL.join(',')
        }
        // 获取检查项
        const checkList = ref({});
        const getCheckList = (ptw_cetificate_type_id,ptw_cetificate_id) =>{
            Http.get({
                method:'query',
                queryId:183,
                ptw_cetificate_type_id:ptw_cetificate_type_id,
                ptw_cetificate_id:ptw_cetificate_id,
                types:'1,2,3,4'
            })
            .then(res =>{
                // checkList.value = res.listptw_check_option_value;
                var rtlist = res.listptw_check_option_value,Obj = {};
                rtlist.forEach(elem =>{
                    if(!Obj[elem.types_name]){
                        Obj[elem.types_name] = [];
                        Obj[elem.types_name].push({
                            name:elem.name,
                            answer:elem.is_y == 1 ?'Y':elem.is_n == 1?'N':elem.is_suit == 1 ?'N/A':'',
                            attachment1:elem.attachment1,
                            commention:elem.commention
                        })
                    }else{
                        Obj[elem.types_name].push({
                            name:elem.name,
                            answer:elem.is_y == 1 ?'Y':elem.is_n == 1?'N':elem.is_suit == 1 ?'N/A':'',
                            attachment1:elem.attachment1,
                            commention:elem.commention
                        })
                    }
                })
                console.log(Obj)
                checkList.value = Obj;
            })
            .catch(()=>{})
        }
        const showImg = (list) =>{
            const baseconfig = Http.config();
            var images = [];
            if(!list || list.length == 0){
                Notify({ type: 'warning', message: '暂无可查看图片' });
                return false;
            }else{
                list.forEach(elem =>{
                    images.push(baseconfig.baseUrl + elem.document_path);
                });
                ImagePreview({
                    images: images,
                    closeable:true,
                    startPosition: 1,
                    loop:true
                });
            }
        }
        // 获取详情
        const getDtail = () =>{
            Http.get({
                method:'detail',
                t:'PTW_CETIFICATE',
                id:route.params.id,
                isTranslate:true
            },false,true,true)
            .then(res=>{
                for(var key in res){
                    detail[key] = res[key];
                }
                detail.ct_contractor_info_id = !res.PTW_CETIFICATE_CT?'':res.PTW_CETIFICATE_CT.ct_contractor_info_id ;
                detail.work_id = !res.PTW_CETIFICATE_CT?'':res.PTW_CETIFICATE_CT.work_id
                detail.supervisor_id = !res.PTW_CETIFICATE_CT?'':res.PTW_CETIFICATE_CT.supervisor_id;
                getJsaDetail(res.tempptw_jsa_id);
                if(res.tempptw_cetificate_type_id && res.tempptw_cetificate_type_id!=''){
                    getCheckList(res.tempptw_cetificate_type_id,res.id)
                }
                
            })
            .catch(()=>{

            })
        }
        // 获取detailjob
        const tempstatus = ref([])
        const getJob = ()=>{
            Http.get({
                method:'detailJob',
                t:'PTW_CETIFICATE',
                id:route.params.id
            },false,true)
            .then(res=>{
                console.log(res)
                actionsLength.value = res.actions.length;
                tempstatus.value = res.status;
            })
            .catch(()=>{

            })
        }
        // 上传签名
        const writingCommit = (res)=>{
            var attachment3 = []
            attachment3.push({document_path:res,document_code:writingType.value});
            Dialog.confirm({
                title: '标题',
                message:writingType.value=='review' ?'是否确认通过评估？':writingType.value=='sign' ?'是否确认签发 ？':'',
            })
            .then(() => {
                Http.post({
                    method:'saveorupdate',
                    t:'PTW_CETIFICATE',
                    action:'success',
                    id:route.params.id,
                    attachment3:attachment3,
                    job_msg:writingType.value=='review' ? job_msg.value:''
                },false,true)
                .then(()=>{
                    getDtail();
                    getJob()
                })
                .catch(()=>{})
            })
            .catch(() => {});
        }
        // 签发
        const onSubmit = (type) => {
            writingType.value = type;
            $this.refs.wirteDialog.show()
        };
        
        // 提交步骤
        const subConfrim = (id,action) =>{
            Http.post({
                method:'saveorupdate',
                t:'PTW_CETIFICATE',
                action:action,
                id:id
            })
            .then(()=>{
                getDtail();
                getJob()
            })
            .catch(()=>{})
        }
        // 评估
        const showAssessment = ref(false);
        const assessment = (type) =>{
            writingType.value = type;
            showAssessment.value = false;
            $this.refs.wirteDialog.show();
            
        }
        const assessmentBack = () =>{
            if(job_msg.value == ''){
                 Notify({ type: 'warning', message: '退回审批，请输入理由！',duration: 2000, });
                 return false;
            }
            Dialog.confirm({
                title: '标题',
                message: '确定退回该作业票？',
            })
            .then(() => {
                Http.post({
                    method:'saveorupdate',
                    t:'PTW_CETIFICATE',
                    action:'fail',
                    id:route.params.id,
                    job_msg:job_msg.value
                })
                .then(()=>{
                    showAssessment.value = false;
                    getDtail();
                    getJob();
                })
                .catch(()=>{})
            })
            .catch(() => {});
        }
        // 点击开始作业按钮,完工申请,整改确认
        const taskSubmit = (id,msg,type) =>{
            Dialog.confirm({
                title: '标题',
                message: msg,
                cancelButtonText:type==1?'中止':'取消'
            })
            .then(() => {
                subConfrim(id,'success');
            })
            .catch(() => {
                if(type == 1){
                    subConfrim(id,'close');
                }
            });
        }
        const cancelSubmit = (id)=>{
            Dialog.confirm({
                title: '标题',
                message: '是否确认中止作业？',
            })
            .then(() => {
                subConfrim(id,'close');
            })
            .catch(() => { });
        }
        onMounted(()=>{
            getDtail();
            getJob()
        });
        return {
            detail,
            writingCommit,
            onSubmit,
            getOtherPtw,
            actionsLength,
            tempstatus,
            taskSubmit,
            app_user_id,
            cancelSubmit,
            showAssessment,
            job_msg,
            assessment,
            writingType,
            assessmentBack,
            jsaDetail,
            getStr,
            checkList,
            showImg,
            ptw_gas_checker
        }
    },
}
</script>
<style lang="less" scoped>
.detailAction{
    height: 45px;
    width: calc(100% - 20px);
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content:center ;
    button{
        margin-right: 10px;
    }
    
}

.checkBox{
    width: 95%;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px auto;
    .title{
        padding:10px;
        text-indent: 5px;
        font-size: 15px;
        font-family: 'blod';
        border-bottom: 1px solid #eeeeee;
    }
    .van-radio-group--horizontal{
        width: calc(100% - 20px);
        padding: 0 10px;
        justify-content: space-between;
        .van-radio{
            border-bottom: 0;
        }
    }
    .button{
        width:100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
</style>